import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout-components/layout'

interface Props {

}

const ErrorPage: React.FC<Props> = () => {
    const pageName = "Site is missing"
    return (
        <Layout pageName={pageName}>
            <div>
                <br />
                <h1>Oops, something went wrong!</h1>
                <p>No page with this name.</p>
                <Link to={'/'}>Back</Link>
                <br />
                <br />
                <br />
            </div>
        </Layout>
    )
}

export default ErrorPage